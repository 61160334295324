import { useRouter } from "next/router";
import { useEffect } from "react";
import { getReferrer } from "src/utils/getReferrer";
import { isBrowser } from "src/utils/isBrowser";
import { emitPageViewEvent } from "./events";

export function useTrackRouting(): void {
  const router = useRouter();
  const referer = getReferrer();

  useEffect(() => {
    const path = `${router.basePath}${router.asPath}`;

    let utm_source: string | undefined;
    let utm_medium: string | undefined;
    let utm_campaign: string | undefined;

    // For PSE tracking
    if (path.startsWith("/editor")) {
      utm_source = "partner";
      utm_medium = "embed";
      utm_campaign = "partner_editor";
    }

    const shouldEmit =
      router.isReady &&
      !(path.includes("[") && path.includes("]")) &&
      !path.includes("__internal") &&
      isBrowser;
    if (shouldEmit) {
      emitPageViewEvent({ referer, utm_source, utm_medium, utm_campaign });
    }
  }, [
    router.isReady,
    router.basePath,
    router.asPath,
    router.pathname,
    referer,
  ]);
}
